import { render, staticRenderFns } from "./CMOverviewHeader.vue?vue&type=template&id=564b8d57&scoped=true&lang=pug&"
import script from "./CMOverviewHeader.vue?vue&type=script&lang=js&"
export * from "./CMOverviewHeader.vue?vue&type=script&lang=js&"
import style0 from "./CMOverviewHeader.vue?vue&type=style&index=0&id=564b8d57&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "564b8d57",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VIcon,VSwitch})

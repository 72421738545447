<template lang="pug">
  thead.v-data-table-header
    th(nowrap v-for="(header, index) in headerRow.props.headers" :key="index" :style="{minWidth: getWidth(index)}")
      v-icon(small color="GRAY").header-icon {{ header.icon }}
      span(v-if="header.value !== 'switcher'").header-title {{ header.text }}

      div.header-filter(v-if="header.value === 'location'")
        select-menu(
          :options="crmCourseLocation"
          itemText="name"
          itemValue="ID"
          :selectedOptions="filters.city"
          @change="$emit('updateFilters', { filter: FILTER_NAMES.CITY, value: $event})"
        )

      div.header-filter(v-if="header.value === 'date'")
        dateFilter(
          :date_before="filters.date_before"
          :date_after="filters.date_after"
          @change="$emit('dateFilterChanged', $event)")

      v-switch(
        v-if="header.value === 'switcher'"
        :input-value="filters[FILTER_NAMES.HIDE_COURSES_WITHOUT_RESERVATION]"
        :color="BLUE"
        label="Hide without reservation"
        data-test="cm-hide-empty-switch"
        @change="$emit('updateFilters', { filter: FILTER_NAMES.HIDE_COURSES_WITHOUT_RESERVATION, value: $event})"
      )

</template>

<script>
  import { mapGetters } from 'vuex'
  import { CMOverviewFilters, FILTER_NAMES } from '../classes/CMOverviewFilters'

  const GRAY = '#888a95';
  const BLUE = '#4e7fed';

  const HEADER_CELL_WIDTH = {
    DAY_WEEK_NAME: '160px',
    NAME: '150px',
    LOCATION: '390px',
    EXAM_LOCATION: '270px',
    EXAM_DATE: '120px',
    EXAM_TIME: '',
    COURSE_LEADERS: '220px'
  }

  export default {
    props: {
      filters: {
        type: CMOverviewFilters,
        required: true,
      },
      headerRow: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      cellWidths: Object.values(HEADER_CELL_WIDTH),
      FILTER_NAMES,
      GRAY,
      BLUE,
    }),
    computed: {
      ...mapGetters({
        crmCourseLocation: 'crmCourseCities/items'
      }),
    },
    methods: {
      getWidth(index) {
        return this.cellWidths[index]
      }
    },
    components: {
      selectMenu: () => import('./LocationSelectMenu.vue'),
      dateFilter: () => import('./CMDateFilter.vue'),
    }
  }
</script>

<style lang="scss" scoped>
  .header-title{
    color: $label-color;
    margin-left: 4px;
  }

  .header-filter{
    display: inline-block;
  }

  ::v-deep{
    .v-input--selection-controls.v-input{
      margin: 0;

      .v-label{
        font-size: 12px;
      }
    }
  }
</style>
